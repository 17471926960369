/* CSS */
.text-custom-color {
  color: #4D3B47;
}
/* SCSS */
.text-custom-color {
  color: #4D3B47;
}
.bg-custom-gray{
  background-color: #4D3B47;
}

.bg-second-color{
  background-color: #CC9674;
}